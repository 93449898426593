import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import ImobiliariaReducer from '../imobiliaria/imobiliariaReducer';
import CadastroReducer from '../cadastro/cadastroReducer';
import UnidadeReducer from '../unidade/unidadeReducer';
import EmpreendimentoReducer from '../empreendimento/empreendimentoReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
	dashboard: DashboardReducer,
	usuario: UsuarioReducer,
	imobiliaria: ImobiliariaReducer,
	cadastro: CadastroReducer,
	empreendimento: EmpreendimentoReducer,
	unidade: UnidadeReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;

