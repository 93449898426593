const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [],
    listaCadastro: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'EMPREENDIMENTO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'EMPREENDIMENTO_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'EMPREENDIMENTO_CADASTRO_LISTADO':
            return { ...state, listaCadastro: action.payload.data };

        default:
            return state;
    }
}
