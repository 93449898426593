import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import { setModoTela, initFormReserva as initForm } from './unidadeActions';

class UnidadeReservaForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							{this.props.formularioValues.cadastro.map(item => {
								
								let componente = null;
								let casas = null;
								if (item.id_tipo == 1) {
									//Texto
									return (
										<LabelAndInput
											key={item.id}
											label={item.nome} placeholder='Informe'
											cols='12 4 4 4'
											readOnly={readOnly}
											value={item.valor}
											onChange={data => {
												this.props.initForm({
													...this.props.formularioValues,
													cadastro: this.props.formularioValues.cadastro.map(itemAux => {
														if (itemAux.id == item.id) {
															return {
																...itemAux,
																valor: data.target.value
															};
														} else {
															return itemAux;
														}
													})
												});
											}} />
									)
								} else if (item.id_tipo == 2) {
									//Número Inteiro
									return (
										<LabelAndInputNumber
											key={item.id}
											label={item.nome} placeholder='Informe'
											cols='12 4 4 4'
											readOnly={readOnly}
											value={item.valor}
											casas={0}
											onChange={data => {
												this.props.initForm({
													...this.props.formularioValues,
													cadastro: this.props.formularioValues.cadastro.map(itemAux => {
														if (itemAux.id == item.id) {
															return {
																...itemAux,
																valor: data.target.value
															};
														} else {
															return itemAux;
														}
													})
												});
											}} />
									);
								} else if (item.id_tipo == 3) {
									//Número Decimal
									return (
										<LabelAndInputNumber
											key={item.id}
											label={item.nome} placeholder='Informe'
											cols='12 4 4 4'
											readOnly={readOnly}
											value={item.valor}
											casas={2}
											onChange={data => {
												this.props.initForm({
													...this.props.formularioValues,
													cadastro: this.props.formularioValues.cadastro.map(itemAux => {
														if (itemAux.id == item.id) {
															return {
																...itemAux,
																valor: data.target.value
															};
														} else {
															return itemAux;
														}
													})
												});
											}} />
									);
								} else if (item.id_tipo == 4) {
									//4 - Data
									return (
										<LabelAndInputDate
											key={item.id}
											label={item.nome} placeholder='Informe'
											cols='12 4 4 4'
											readOnly={readOnly}
											value={item.valor}
											onChange={data => {
												this.props.initForm({
													...this.props.formularioValues,
													cadastro: this.props.formularioValues.cadastro.map(itemAux => {
														if (itemAux.id == item.id) {
															return {
																...itemAux,
																valor: data.target.value
															};
														} else {
															return itemAux;
														}
													})
												});
											}} />
									);
								}
							})}

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

UnidadeReservaForm = reduxForm({form: 'unidadeReservaForm', destroyOnUnmount: false})(UnidadeReservaForm);
const mapStateToProps = state => ({
	sessao: state.auth.unidade,
	formularioValues: getFormValues('unidadeReservaForm')(state),
	registro: state.unidade.registro,
	listaRegiao: state.unidade.listaRegiao,
	listaLocalEstoque: state.unidade.listaLocalEstoque
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UnidadeReservaForm);
