import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuItem from './menuItem'
import MenuTree from './menuTree'
import { withRouter } from 'react-router-dom';

import { carregarUsuario } from '../../auth/authActions';

class Menu extends Component {

	state = {
		dashboardAberto: null,
		acessoAberto: null,
		cadastroAberto: null,
		precoAberto: null,
		vendaAberto: null,
		logisticaAberto: null,
		estoqueAberto: null,
		metaAberto: null,
		manutencaoAberto: null
	};

	constructor(props) {
		super(props);

		let idInterval = setInterval(() => {
			if (this.props.usuario) {
				this.props.carregarUsuario();
				clearInterval(idInterval);
			}
		}, 2000);
	}

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		/*if (this.props.usuarioCarregado) {
			if (!this.props.usuarioCarregado.acesso && (selecionado == '/usuario')) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.cadastro 
				&& (
					selecionado == '/regiao' || selecionado == '/loja' || selecionado == '/produtoCategoria' || selecionado == '/produto'
				)
			) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.pedido_solicitacao 
				&& (
					selecionado == '/'
				)
			) {
				window.location = '/';
			}
		}*/

		return (
			<nav className="mt-2">
								    
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
					
					{(this.props.usuarioCarregado && this.props.usuarioCarregado.administrador) &&
					<MenuItem path='usuario' label='Usuário' icon='fas fa-users' active={selecionado == '/usuario'} />}

					{(this.props.usuarioCarregado && this.props.usuarioCarregado.administrador) &&
					<MenuItem path='imobiliaria' label='Imobiliária' icon='fas fa-store-alt' active={selecionado == '/imobiliaria'} />}

					{(this.props.usuarioCarregado && this.props.usuarioCarregado.administrador) &&
					<MenuItem path='cadastro' label='Cadastro' icon='fas fa-clipboard-list' active={selecionado == '/cadastro'} />}

					{(this.props.usuarioCarregado && this.props.usuarioCarregado.administrador) &&
					<MenuItem path='empreendimento' label='Empreendimento' icon='fas fa-building' active={selecionado == '/empreendimento'} />}

				</ul>
			</nav>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado
});
const mapDispatchToProps = dispatch => bindActionCreators({ carregarUsuario }, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
