import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';

import { setModoTela, initForm } from './usuarioActions';

class UsuarioForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome *' placeholder='Informe o Nome'
								cols='12 12 12 12'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />

							<Field
								name='login'
								component={LabelAndInput}
								label='Login *'
								placeholder='Informe o Usuário'
								cols='12 12 12 12'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />

							{(this.props.cadastroSenha || !this.props.formularioValues.id) &&
							<Field
								name='senha'
								component={LabelAndInput}
								type="password"
								label='Senha *'
								placeholder='Informe a Senha'
								cols='12 12 12 12'
								readOnly={readOnly} />}
							
							{(!this.props.cadastroSenha && !this.props.formularioValues.id) &&
							<Select
								component={Select}
								options={[{ id: 1, valor: 'Administrador' }, { id: 2, valor: 'Imobiliária' }, { id: 3, valor: 'Corretor' }]}
								label='Acesso'
								cols='12 12 12 12'
								placeholder='Selecione o acesso'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly}
								value={this.props.formularioValues.corretor ? 3 : (this.props.formularioValues.imobiliaria ? 2 : 1)}
								onChange={value => {
									this.props.initForm({
										...this.props.formularioValues,
										administrador: value == 1,
										imobiliaria: value == 2,
										corretor: value == 3,
										id_imobiliaria: null
									})
								}} />}

							{(!this.props.cadastroSenha && !this.props.formularioValues.id && this.props.formularioValues.imobiliaria) &&
							<Field
								name='id_imobiliaria'
								component={Select}
								options={this.props.listaImobiliaria}
								label='Imobiliária'
								cols='12 12 12 12'
								placeholder='Selecione a imobiliária'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />}
								
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

UsuarioForm = reduxForm({form: 'usuarioForm', destroyOnUnmount: false})(UsuarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('usuarioForm')(state),
	registro: state.usuario.registro,
	listaImobiliaria: state.usuario.listaImobiliaria
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);
