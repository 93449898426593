import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Unidade from '../unidade/unidade';
import './empreendimento.css';
import imagemLoteamento from '../assets/images/imagem_empreendimento.png';

import { setModoTela, initForm, getListaCadastro } from './empreendimentoActions';
import { setModoTela as setModoTelaUnidade, initFormReserva, salvarReserva } from '../unidade/unidadeActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import UnidadeReservaForm from '../unidade/unidadeReservaForm';


class EmpreendimentoForm extends Component {

	state = {
		registroUnidade: null,
		modoReserva: false,
		zoom: 0.5
	}

    componentWillMount() {

		this.props.setModoTelaUnidade('lista');
		this.props.getListaCadastro();

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let botao1 = this.props.listaUnidade.filter(item => item.id == 1)[0] || {};
		let botao2 = this.props.listaUnidade.filter(item => item.id == 2)[0] || {};
		let botao3 = this.props.listaUnidade.filter(item => item.id == 3)[0] || {};
		let botao4 = this.props.listaUnidade.filter(item => item.id == 4)[0] || {};
		
        return (
			<>

				{this.modalUnidade()}

				{!this.props.visualizar &&
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name='nome'
									component={LabelAndInput}
									label='Nome *' placeholder='Informe o Nome'
									cols='12 12 12 12'
									readOnly={readOnly} />

								<Field
									name='endereco'
									component={LabelAndInput}
									label='Endereço *' placeholder='Informe o endereço'
									cols='12 12 12 12'
									readOnly={readOnly} />

							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>

					</Form>
				</ContentCard>}

				{this.props.formularioValues.id &&
				<Unidade invisivel={this.props.visualizar} id_empreendimento={this.props.formularioValues.id} />}
				
				{this.props.visualizar &&
				<div class="btn-group"
					style={{
						position: 'fixed',
						top: 60,
						left: 260,
						zIndex: 1000
					}}>
                    <button type="button" class="btn btn-secondary"
						onClick={() => this.setState({ ...this.state, zoom: this.state.zoom - 0.05 })}>
						<i class="fas fa-search-minus"></i>
                    </button>
                    <button type="button" class="btn btn-secondary">
						{parseInt(this.state.zoom * 100)}%
                    </button>
                    <button type="button" class="btn btn-secondary"
						onClick={() => this.setState({ ...this.state, zoom: this.state.zoom + 0.05 })}>
						<i class="fas fa-search-plus"></i>
                    </button>
				</div>}

				{this.props.visualizar &&
				<div 
					style={{
						transform: `scale(${this.state.zoom})`,
      					transformOrigin: 'left top',
      					transition: 'transform 0.3s ease', 
					}}>
					<div className="image-container">
						<img
							src={imagemLoteamento}
							className="image" />

						{/* Quadra A */}
						{this.props.listaUnidade.filter(item => item.quadra == 'A').map((unidade, i) => {
							return (
								<button
									className='button'
									style={{
										top: 1335 + (55 * (i - 4)),
										left: 1862,
										width: 54,
										fontSize: 11,
										padding: 2,
										background: unidade.status == 'Vendido' ? '#dedede' : (unidade.status == 'Reservado' ? '#d0ed6b' : null)
									}}
									onClick={() => this.setState({ ...this.state, registroUnidade: unidade.status == 'Vendido' ? null : { ...unidade, percentual_entrada: 20, percentual_reforco1: 5, percentual_reforco2: 5, percentual_reforco3: 5, percentual_reforco4: 5 } })}>
									{unidade.numero}
								</button>
							);
						})}

						{/* Quadra B */}
						{this.props.listaUnidade.filter(item => item.quadra == 'B').map((unidade, i) => {

							let top = null;
							let left = null;
							if (i == 0) {
								top = 1140;
								left = 1717;
							} else if (i == 1) {
								top = 1130;
								left = 1650;
							} else if (i == 2) {
								top = 1120;
								left = 1584;
							} else if (i == 3) {
								top = 1110;
								left = 1516;
							} else {
								let posicao = i - 4;
								top = 1228 + ((posicao % 2 == 0 ? posicao : posicao - 1) * 27);
								//Coluna 1 ou 2
								left = posicao % 2 == 0 ? 1550 : 1685;
							}

							return (
								<button
									className='button'
									style={{
										top: top,
										left: left,
										width: 54,
										fontSize: 11,
										padding: 2,
										background: unidade.status == 'Vendido' ? '#dedede' : (unidade.status == 'Reservado' ? '#d0ed6b' : null)
									}}
									onClick={() => this.setState({ ...this.state, registroUnidade: unidade.status == 'Vendido' ? null : { ...unidade, percentual_entrada: 20, percentual_reforco1: 5, percentual_reforco2: 5, percentual_reforco3: 5, percentual_reforco4: 5 } })}>
									{unidade.numero}
								</button>
							);
						})}

						{/* Quadra C */}
						{this.props.listaUnidade.filter(item => item.quadra == 'C').map((unidade, i) => {

							let top = null;
							let left = null;
							if (i == 0) {
								top = 1030;
								left = 1405;
							} else if (i == 1) {
								top = 1015;
								left = 1337;
							} else if (i == 2) {
								top = 965;
								left = 1271;
							} else if (i == 3) {
								top = 952;
								left = 1204;
							} else if (i == 4) {
								top = 1062;
								left = 1235;
							} else {
								let posicao = i - 5;
								top = 1118 + ((posicao % 2 == 0 ? posicao : posicao - 1) * 27);
								//Coluna 1 ou 2
								left = posicao % 2 == 0 ? 1235 : 1370;
							}

							return (
								<button
									className='button'
									style={{
										top: top,
										left: left,
										width: 54,
										fontSize: 11,
										padding: 2,
										background: unidade.status == 'Vendido' ? '#dedede' : (unidade.status == 'Reservado' ? '#d0ed6b' : null)
									}}
									onClick={() => this.setState({ ...this.state, registroUnidade: unidade.status == 'Vendido' ? null : { ...unidade, percentual_entrada: 20, percentual_reforco1: 5, percentual_reforco2: 5, percentual_reforco3: 5, percentual_reforco4: 5 } })}>
									{unidade.numero}
								</button>
							);
						})}

						{/* Quadra D */}
						{this.props.listaUnidade.filter(item => item.quadra == 'D').map((unidade, i) => (
							<button
								className='button'
								style={{
									top: 791 + (58 * i),
									left: 1045,
									width: 54,
									fontSize: 11,
									padding: 2,
									background: unidade.status == 'Vendido' ? '#dedede' : (unidade.status == 'Reservado' ? '#d0ed6b' : null)
								}}
								onClick={() => this.setState({ ...this.state, registroUnidade: unidade.status == 'Vendido' ? null : { ...unidade, percentual_entrada: 20, percentual_reforco1: 5, percentual_reforco2: 5, percentual_reforco3: 5, percentual_reforco4: 5 } })}>
								{unidade.numero}
							</button>
						))}

						{/* Quadra E */}
						{this.props.listaUnidade.filter(item => item.quadra == 'E').map((unidade, i) => {

							let top = 1352 + ((i % 2 == 0 ? i : i - 1) * 27.5);
							//Coluna 1 ou 2
							let left = i % 2 == 0 ? 1548 : 1688;

							return (
								<button
									className='button'
									style={{
										top: top,
										left: left,
										width: 54,
										fontSize: 11,
										padding: 2,
										background: unidade.status == 'Vendido' ? '#dedede' : (unidade.status == 'Reservado' ? '#d0ed6b' : null)
									}}
									onClick={() => this.setState({ ...this.state, registroUnidade: unidade.status == 'Vendido' ? null : { ...unidade, percentual_entrada: 20, percentual_reforco1: 5, percentual_reforco2: 5, percentual_reforco3: 5, percentual_reforco4: 5 } })}>
									{unidade.numero}
								</button>
							);
						})}

						{/* Quadra F */}
						{this.props.listaUnidade.filter(item => item.quadra == 'F').map((unidade, i) => {

							let top = 1351 + ((i % 2 == 0 ? i : i - 1) * 27.5);
							//Coluna 1 ou 2
							let left = i % 2 == 0 ? 1230 : 1370;

							return (
								<button
									className='button'
									style={{
										top: top,
										left: left,
										width: 54,
										fontSize: 11,
										padding: 2,
										background: unidade.status == 'Vendido' ? '#dedede' : (unidade.status == 'Reservado' ? '#d0ed6b' : null)
									}}
									onClick={() => this.setState({ ...this.state, registroUnidade: unidade.status == 'Vendido' ? null : { ...unidade, percentual_entrada: 20, percentual_reforco1: 5, percentual_reforco2: 5, percentual_reforco3: 5, percentual_reforco4: 5 } })}>
									{unidade.numero}
								</button>
							);
						})}

						{/* Quadra G */}
						{this.props.listaUnidade.filter(item => item.quadra == 'G').map((unidade, i) => {

							let top = 1329 + ((i % 2 == 0 ? i : i - 1) * 29);
							//Coluna 1 ou 2
							let left = i % 2 == 0 ? 905 : 1045;

							return (
								<button
									className='button'
									style={{
										top: top,
										left: left,
										width: 54,
										fontSize: 11,
										padding: 2,
										background: unidade.status == 'Vendido' ? '#dedede' : (unidade.status == 'Reservado' ? '#d0ed6b' : null)
									}}
									onClick={() => this.setState({ ...this.state, registroUnidade: unidade.status == 'Vendido' ? null : { ...unidade, percentual_entrada: 20, percentual_reforco1: 5, percentual_reforco2: 5, percentual_reforco3: 5, percentual_reforco4: 5 } })}>
									{unidade.numero}
								</button>
							);
						})}

						{/* Quadra H */}
						{this.props.listaUnidade.filter(item => item.quadra == 'H').map((unidade, i) => {

							let top = 1333 + ((i % 2 == 0 ? i : i - 1) * 29);
							//Coluna 1 ou 2
							let left = i % 2 == 0 ? 585 : 725;

							return (
								<button
									className='button'
									style={{
										top: top,
										left: left,
										width: 54,
										fontSize: 11,
										padding: 2,
										background: unidade.status == 'Vendido' ? '#dedede' : (unidade.status == 'Reservado' ? '#d0ed6b' : null)
									}}
									onClick={() => this.setState({ ...this.state, registroUnidade: unidade.status == 'Vendido' ? null : { ...unidade, percentual_entrada: 20, percentual_reforco1: 5, percentual_reforco2: 5, percentual_reforco3: 5, percentual_reforco4: 5 } })}>
									{unidade.numero}
								</button>
							);
						})}

						{/* Quadra I */}
						{this.props.listaUnidade.filter(item => item.quadra == 'I').map((unidade, i) => {

							let top = 1333 + ((i % 2 == 0 ? i : i - 1) * 29);
							//Coluna 1 ou 2
							let left = i % 2 == 0 ? 265 : 405;

							return (
								<button
									className='button'
									style={{
										top: top,
										left: left,
										width: 54,
										fontSize: 11,
										padding: 2,
										background: unidade.status == 'Vendido' ? '#dedede' : (unidade.status == 'Reservado' ? '#d0ed6b' : null)
									}}
									onClick={() => this.setState({ ...this.state, registroUnidade: unidade.status == 'Vendido' ? null : { ...unidade, percentual_entrada: 20, percentual_reforco1: 5, percentual_reforco2: 5, percentual_reforco3: 5, percentual_reforco4: 5 } })}>
									{unidade.numero}
								</button>
							);
						})}

						{/* Quadra J */}
						{this.props.listaUnidade.filter(item => item.quadra == 'J').map((unidade, i) => (
							<button
								className='button'
								style={{
									top: 1301 + (62 * i),
									left: 80,
									width: 54,
									fontSize: 11,
									padding: 2,
									background: unidade.status == 'Vendido' ? '#dedede' : (unidade.status == 'Reservado' ? '#d0ed6b' : null)
								}}
								onClick={() => this.setState({ ...this.state, registroUnidade: unidade.status == 'Vendido' ? null : { ...unidade, percentual_entrada: 20, percentual_reforco1: 5, percentual_reforco2: 5, percentual_reforco3: 5, percentual_reforco4: 5 } })}>
								{unidade.numero}
							</button>
						))}

						{/* Quadra K */}
						{this.props.listaUnidade.filter(item => item.quadra == 'K').map((unidade, i) => (
							<button
								className='button'
								style={{
									top: 1790,
									left: 1716 - (57.3 * i),
									width: 54,
									fontSize: 11,
									padding: 2,
									background: unidade.status == 'Vendido' ? '#dedede' : (unidade.status == 'Reservado' ? '#d0ed6b' : null)
								}}
								onClick={() => this.setState({ ...this.state, registroUnidade: unidade.status == 'Vendido' ? null : { ...unidade, percentual_entrada: 20, percentual_reforco1: 5, percentual_reforco2: 5, percentual_reforco3: 5, percentual_reforco4: 5 } })}>
								{unidade.numero}
							</button>
						))}

						{/* Quadra L */}
						{this.props.listaUnidade.filter(item => item.quadra == 'L').map((unidade, i) => (
							<button
								className='button'
								style={{
									top: 1790,
									left: 925 - (57.3 * i),
									width: 54,
									fontSize: 11,
									padding: 2,
									background: unidade.status == 'Vendido' ? '#dedede' : (unidade.status == 'Reservado' ? '#d0ed6b' : null)
								}}
								onClick={() => this.setState({ ...this.state, registroUnidade: unidade.status == 'Vendido' ? null : { ...unidade, percentual_entrada: 20, percentual_reforco1: 5, percentual_reforco2: 5, percentual_reforco3: 5, percentual_reforco4: 5 } })}>
								{unidade.numero}
							</button>
						))}
					</div>
				</div>}

			</>
        )
    }
	
	modalUnidade() {

		if (!this.state.registroUnidade) {
			return null;
		}

		const registro = this.props.listaUnidade.filter(item => item.id == this.state.registroUnidade.id)[0];

		if (!registro) {
			return null;
		}

		let valorBase = parseFloat(this.state.registroUnidade.preco_avista || 0);
		let valorEntrada = (parseFloat(this.state.registroUnidade.percentual_entrada || 0) * valorBase) / 100.0;

		let valorReforco1 = (parseFloat(this.state.registroUnidade.percentual_reforco1 || 0) * valorBase) / 100.0;

		let valorReforco2 = (parseFloat(this.state.registroUnidade.percentual_reforco2 || 0) * valorBase) / 100.0;

		let valorReforco3 = (parseFloat(this.state.registroUnidade.percentual_reforco3 || 0) * valorBase) / 100.0;

		let valorReforco4 = (parseFloat(this.state.registroUnidade.percentual_reforco4 || 0) * valorBase) / 100.0;

		let parcelas = this.state.registroUnidade.parcelas || 1;
		let valorParcela = (valorBase - valorEntrada - valorReforco1 - valorReforco2 - valorReforco3 - valorReforco4) / parcelas;

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>{registro.numero}</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, registroUnidade: null, modoReserva: false })}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ maxHeight: window.innerHeight - 300, overflowY: 'scroll', paddingBottom: 400 }}>
							{!this.state.modoReserva ? (
								<>
									<Row>
										<Grid cols='12 12 12 12'>
											<p><b>Status:</b> {registro.status}</p>
											<p><b>Quadra:</b> {registro.quadra}</p>
											<p><b>Número:</b> {registro.numero}</p>
											<p><b>Descrição:</b> {registro.descricao}</p>
											<p><b>Leste:</b> {FormatUtils.formatarValorTela(registro.leste || 0, 4)} m</p>
											<p><b>Oeste:</b> {FormatUtils.formatarValorTela(registro.oeste || 0, 4)} m</p>
											<p><b>Norte:</b> {FormatUtils.formatarValorTela(registro.norte || 0, 4)} m</p>
											<p><b>Sul:</b> {FormatUtils.formatarValorTela(registro.sul || 0, 4)} m</p>
											<p><b>Dimensão:</b> {FormatUtils.formatarValorTela(registro.dimensao || 0, 4)} m²</p>
											<p><b>Preço à Vista:</b> {FormatUtils.formatarValorTela(registro.preco_avista, 2)}</p>
										</Grid>
									</Row>
									{registro.status != 'Vendido' &&
									<Row>
										<Grid cols='6 6 6 4'>
											<Button
												text={registro.status == 'Reservado' ? 'Adicionar na Fila de Reserva' : 'Reservar'}
												type={'primary'}
												icon={'fa fa-plus'}
												event={() => {
													this.props.initFormReserva({
														id_empreendimento: registro.id_empreendimento,
														id_unidade: registro.id,
														cadastro: this.props.listaCadastro.map(item => ({
															id: item.id,
															id_tipo: item.id_tipo,
															nome: item.nome,
															valor: null
														})),
														evento: () => {
															this.setState({ ...this.state, modoReserva: false });
														}
													});
													this.setState({
														...this.state,
														modoReserva: true
													});
												}} />
										</Grid>
									</Row>}

									<p style={{ marginTop: 16 }}><b>Simular</b></p>

									<Row>
										<LabelAndInputNumber
											label='Entrada (%)' placeholder='Informe'
											cols='12 6 4 2'
											casas={0}
											value={this.state.registroUnidade.percentual_entrada}
											onChange={data => {
												this.setState({
													...this.state,
													registroUnidade: {
														...this.state.registroUnidade,
														percentual_entrada: data.target.value
													}
												});
											}} />

										<LabelAndInputNumber
											label='Entrada (R$)' placeholder='Informe'
											cols='12 6 4 2'
											casas={2}
											value={FormatUtils.formatarValorTela(valorEntrada, 2)}
											readOnly={'readOnly'}
											onChange={data => {}} />
									</Row>

									<Row>
										<LabelAndInputNumber
											label='Reforço 6º mês (%)' placeholder='Informe'
											cols='12 6 4 2'
											casas={0}
											value={this.state.registroUnidade.percentual_reforco1}
											onChange={data => {
												this.setState({
													...this.state,
													registroUnidade: {
														...this.state.registroUnidade,
														percentual_reforco1: data.target.value
													}
												});
											}} />

										<LabelAndInputNumber
											label='Reforço 6º mês (R$)' placeholder='Informe'
											cols='12 6 4 2'
											casas={2}
											value={FormatUtils.formatarValorTela(valorReforco1, 2)}
											readOnly={'readOnly'}
											onChange={data => {}} />
									</Row>

									<Row>
										<LabelAndInputNumber
											label='Reforço 12º mês (%)' placeholder='Informe'
											cols='12 6 4 2'
											casas={0}
											value={this.state.registroUnidade.percentual_reforco2}
											onChange={data => {
												this.setState({
													...this.state,
													registroUnidade: {
														...this.state.registroUnidade,
														percentual_reforco2: data.target.value
													}
												});
											}} />

										<LabelAndInputNumber
											label='Reforço 12º mês (R$)' placeholder='Informe'
											cols='12 6 4 2'
											casas={2}
											value={FormatUtils.formatarValorTela(valorReforco2, 2)}
											readOnly={'readOnly'}
											onChange={data => {}} />
									</Row>

									<Row>
										<LabelAndInputNumber
											label='Reforço 18º mês (%)' placeholder='Informe'
											cols='12 6 4 2'
											casas={0}
											value={this.state.registroUnidade.percentual_reforco3}
											onChange={data => {
												this.setState({
													...this.state,
													registroUnidade: {
														...this.state.registroUnidade,
														percentual_reforco3: data.target.value
													}
												});
											}} />

										<LabelAndInputNumber
											label='Reforço 18º mês (R$)' placeholder='Informe'
											cols='12 6 4 2'
											casas={2}
											value={FormatUtils.formatarValorTela(valorReforco3, 2)}
											readOnly={'readOnly'}
											onChange={data => {}} />
									</Row>

									<Row>
										<LabelAndInputNumber
											label='Reforço 24º mês (%)' placeholder='Informe'
											cols='12 6 4 2'
											casas={0}
											value={this.state.registroUnidade.percentual_reforco4}
											onChange={data => {
												this.setState({
													...this.state,
													registroUnidade: {
														...this.state.registroUnidade,
														percentual_reforco4: data.target.value
													}
												});
											}} />

										<LabelAndInputNumber
											label='Reforço 24º mês (R$)' placeholder='Informe'
											cols='12 6 4 2'
											casas={2}
											value={FormatUtils.formatarValorTela(valorReforco4, 2)}
											readOnly={'readOnly'}
											onChange={data => {}} />
									</Row>

									<Row>
										
										<Select
											options={[
												{ id: 60, valor: '60 parcelas' }, { id: 48, valor: '48 parcelas' }, { id: 36, valor: '36 parcelas' },
												{ id: 24, valor: '24 parcelas' }, { id: 12, valor: '12 parcelas' }, { id: 3, valor: '3 safras' }
											]}
											label='Parcelas'
											cols='12 12 8 4'
											placeholder='Selecione o tipo'
											value={this.state.registroUnidade.parcelas}
											onChange={value => {
												this.setState({
													...this.state,
													registroUnidade: {
														...this.state.registroUnidade,
														parcelas: value
													}
												});
											}} />
									
									</Row>

									<Row>
										<LabelAndInputNumber
											label='Parcela (R$)' placeholder='Informe'
											cols='12 6 4 2'
											casas={2}
											value={FormatUtils.formatarValorTela(valorParcela, 2)}
											readOnly={'readOnly'}
											onChange={data => {}} />
									</Row>

								</> 
							) : (
								<UnidadeReservaForm onSubmit={this.props.salvarReserva} />
							)}
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => {
									this.setState({ ...this.state, registroUnidade: null, modoReserva: false });
								}}>Ok</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

EmpreendimentoForm = reduxForm({form: 'empreendimentoForm', destroyOnUnmount: false})(EmpreendimentoForm);
const mapStateToProps = state => ({
	sessao: state.auth.empreendimento,
	formularioValues: getFormValues('empreendimentoForm')(state),
	registro: state.empreendimento.registro,
	listaRegiao: state.empreendimento.listaRegiao,
	listaLocalEstoque: state.empreendimento.listaLocalEstoque,
	listaUnidade: state.unidade.lista,
	listaCadastro: state.empreendimento.listaCadastro
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, setModoTelaUnidade, initFormReserva, salvarReserva, getListaCadastro }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmpreendimentoForm);
