import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import { setModoTela, initForm } from './unidadeActions';

class UnidadeForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='quadra'
								component={LabelAndInput}
								label='Quadra *' placeholder='Informe a quadra'
								cols='12 4 3 2'
								readOnly={readOnly} />

							<Field
								name='numero'
								component={LabelAndInput}
								label='Número *' placeholder='Informe o número'
								cols='12 4 3 2'
								readOnly={readOnly} />

							<Field
								name='descricao'
								component={LabelAndInput}
								label='Descrição *' placeholder='Informe a descrição'
								cols='12 12 9 10'
								readOnly={readOnly} />

							<Field
								name='leste'
								component={LabelAndInputNumber}
								label='Leste' placeholder='Informe'
								cols='12 12 12 12'
								casas={4}
								readOnly={readOnly} />

							<Field
								name='oeste'
								component={LabelAndInputNumber}
								label='Oeste' placeholder='Informe'
								cols='12 12 12 12'
								casas={4}
								readOnly={readOnly} />
							
							<Field
								name='norte'
								component={LabelAndInputNumber}
								label='Norte' placeholder='Informe'
								cols='12 12 12 12'
								casas={4}
								readOnly={readOnly} />
							
							<Field
								name='sul'
								component={LabelAndInputNumber}
								label='Sul' placeholder='Informe'
								cols='12 12 12 12'
								casas={4}
								readOnly={readOnly} />

							<Field
								name='dimensao'
								component={LabelAndInputNumber}
								label='Dimensão' placeholder='Informe'
								cols='12 12 12 12'
								casas={4}
								readOnly={readOnly} />
							
							<Field
								name='preco_avista'
								component={LabelAndInputNumber}
								label='Preço (À Vista)' placeholder='Informe o preço'
								cols='12 12 12 12'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='preco_prazo'
								component={LabelAndInputNumber}
								label='Preço (A Prazo)' placeholder='Informe o preço'
								cols='12 12 12 12'
								casas={2}
								readOnly={readOnly} />

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

UnidadeForm = reduxForm({form: 'unidadeForm', destroyOnUnmount: false})(UnidadeForm);
const mapStateToProps = state => ({
	sessao: state.auth.unidade,
	formularioValues: getFormValues('unidadeForm')(state),
	registro: state.unidade.registro,
	listaRegiao: state.unidade.listaRegiao,
	listaLocalEstoque: state.unidade.listaLocalEstoque
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UnidadeForm);
