import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import Usuario from '../usuario/usuario';
import Imobiliaria from '../imobiliaria/imobiliaria';
import Cadastro from '../cadastro/cadastro';
import Empreendimento from '../empreendimento/empreendimento';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/imobiliaria' component={Imobiliaria} />
			<Route path='/cadastro' component={Cadastro} />
			<Route path='/empreendimento' component={Empreendimento} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
