import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './unidadeForm';
import FormatUtils from '../common/formatUtils/FormatUtils';

import { setModoTela, initForm, salvar, excluir, getLista } from './unidadeActions';

class Unidade extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista(this.props.id_empreendimento);
    }

    render() {
        return (
            <div>
				{!this.props.invisivel &&
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>}
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({
										id_empreendimento: this.props.id_empreendimento
									});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Quadra</Th>
								<Th alignCenter>Número</Th>
								<Th alignCenter>Descrição</Th>
								<Th alignCenter>Leste</Th>
								<Th alignCenter>Oeste</Th>
								<Th alignCenter>Norte</Th>
								<Th alignCenter>Sul</Th>
								<Th alignCenter>Dimensão</Th>
								<Th alignCenter>À Vista</Th>
								<Th alignCenter>A Prazo</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.pesquisar
									&& !(`${item.numero} ${item.descricao}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{item.quadra}</Td>
									<Td alignCenter>{item.numero}</Td>
									<Td alignCenter>{item.descricao}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.leste || 0, 4)} m</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.oeste || 0, 4)} m</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.norte || 0, 4)} m</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.sul || 0, 4)} m</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.dimensao || 0, 4)} m²</Td>
									<Td alignCenter>R$ {FormatUtils.formatarValorTela(item.preco_avista, 2)}</Td>
									<Td alignCenter>R$ {FormatUtils.formatarValorTela(item.preco_prazo, 2)}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													leste: FormatUtils.formatarValorTela(item.leste || 0, 4),
													oeste: FormatUtils.formatarValorTela(item.oeste || 0, 4),
													norte: FormatUtils.formatarValorTela(item.norte || 0, 4),
													sul: FormatUtils.formatarValorTela(item.sul || 0, 4),
													dimensao: FormatUtils.formatarValorTela(item.dimensao || 0, 4),
													preco_avista: FormatUtils.formatarValorTela(item.preco_avista, 2),
													preco_prazo: FormatUtils.formatarValorTela(item.preco_prazo, 2)
												});
												this.props.initForm({
													...item,
													leste: FormatUtils.formatarValorTela(item.leste || 0, 4),
													oeste: FormatUtils.formatarValorTela(item.oeste || 0, 4),
													norte: FormatUtils.formatarValorTela(item.norte || 0, 4),
													sul: FormatUtils.formatarValorTela(item.sul || 0, 4),
													dimensao: FormatUtils.formatarValorTela(item.dimensao || 0, 4),
													preco_avista: FormatUtils.formatarValorTela(item.preco_avista, 2),
													preco_prazo: FormatUtils.formatarValorTela(item.preco_prazo, 2)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													leste: FormatUtils.formatarValorTela(item.leste || 0, 4),
													oeste: FormatUtils.formatarValorTela(item.oeste || 0, 4),
													norte: FormatUtils.formatarValorTela(item.norte || 0, 4),
													sul: FormatUtils.formatarValorTela(item.sul || 0, 4),
													dimensao: FormatUtils.formatarValorTela(item.dimensao || 0, 4),
													preco_avista: FormatUtils.formatarValorTela(item.preco_avista, 2),
													preco_prazo: FormatUtils.formatarValorTela(item.preco_prazo, 2)
												});
												this.props.initForm({
													...item,
													leste: FormatUtils.formatarValorTela(item.leste || 0, 4),
													oeste: FormatUtils.formatarValorTela(item.oeste || 0, 4),
													norte: FormatUtils.formatarValorTela(item.norte || 0, 4),
													sul: FormatUtils.formatarValorTela(item.sul || 0, 4),
													dimensao: FormatUtils.formatarValorTela(item.dimensao || 0, 4),
													preco_avista: FormatUtils.formatarValorTela(item.preco_avista, 2),
													preco_prazo: FormatUtils.formatarValorTela(item.preco_prazo, 2)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.unidade,
	modoTela: state.unidade.modoTela,
	lista: state.unidade.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Unidade);
