import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import imagemUser from '../assets/images/user.jpg';
import { 
	getLista, getListaTerceiros, getListaEntradas, getListaSaidas, getListaTransferencias, 
	getListaUnidade, getListaProduto , getListaPosicaoVeiculo, getListaManual
} from './dashboardActions';

import contentCardBody from '../common/template/contentCardBody';

class Dashboard extends Component {

	state = {
		pesquisar: ''
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {

    }

    render() {

		let totalS10EstoqueManual = 0;
		let totalS10EstoqueBase = 0;
		let totalS10SobreRodas = 0;
		let totalS10Faturar = 0;
		let totalS10Faturado = 0;
		let totalS10Disponibilidade = 0;
		let totalS10Programado = 0;

		let totalS500EstoqueManual = 0;
		let totalS500EstoqueBase = 0;
		let totalS500SobreRodas = 0;
		let totalS500Faturar = 0;
		let totalS500Faturado = 0;
		let totalS500Disponibilidade = 0;
		let totalS500Programado = 0;

        return (
			<Content>

			
			</Content>
        )
	}

	setDialogItens(itens) {
		this.setState({
			...this.state,
			itens: itens
		})
	}
	
	modalItens() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'></h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setDialogItens(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ maxHeight: '80%' }}>
							<Row>
								{(this.state.itens || []).map(item => (
									<p key={item}>{item}</p>
								))}								
							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => {
									this.setDialogItens(null);
								}}>Ok</button>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.dashboard.aguardando,
	lista: state.dashboard.lista,
	listaTerceiro: state.dashboard.listaTerceiro,
	listaEntrada: state.dashboard.listaEntrada,
	listaSaida: state.dashboard.listaSaida,
	listaTransferencia: state.dashboard.listaTransferencia,
	listaUnidade: state.dashboard.listaUnidade,
	listaProduto: state.dashboard.listaProduto,
	listaPosicaoVeiculo: state.dashboard.listaPosicaoVeiculo,
	listaManual: state.dashboard.listaManual
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getLista, getListaTerceiros, getListaEntradas, getListaSaidas, getListaTransferencias, getListaUnidade, getListaProduto, getListaPosicaoVeiculo, getListaManual
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
