const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'IMOBILIARIA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'IMOBILIARIA_LISTADO':
            return { ...state, lista: action.payload.data };

        default:
            return state;
    }
}
