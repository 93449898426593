import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'UNIDADE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initForm(registro = {}) {
    return [
        initialize('unidadeForm', registro)
    ];
}

export function getLista(idEmpreendimento) {
    const request = axios.get(`${consts.API_URL}/unidade?id_empreendimento=${idEmpreendimento}`);
    return {
        type: 'UNIDADE_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/unidade`, {
				...registro,
				leste: FormatUtils.formatarValorBanco(registro.leste || 0, 4),
				oeste: FormatUtils.formatarValorBanco(registro.oeste || 0, 4),
				norte: FormatUtils.formatarValorBanco(registro.norte || 0, 4),
				sul: FormatUtils.formatarValorBanco(registro.sul || 0, 4),
				dimensao: FormatUtils.formatarValorBanco(registro.dimensao || 0, 4),
				preco_avista: FormatUtils.formatarValorBanco(registro.preco_avista, 2),
				preco_prazo: FormatUtils.formatarValorBanco(registro.preco_prazo, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_empreendimento));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/unidade`, {
				...registro,
				leste: FormatUtils.formatarValorBanco(registro.leste || 0, 4),
				oeste: FormatUtils.formatarValorBanco(registro.oeste || 0, 4),
				norte: FormatUtils.formatarValorBanco(registro.norte || 0, 4),
				sul: FormatUtils.formatarValorBanco(registro.sul || 0, 4),
				dimensao: FormatUtils.formatarValorBanco(registro.dimensao || 0, 4),
				preco_avista: FormatUtils.formatarValorBanco(registro.preco_avista, 2),
				preco_prazo: FormatUtils.formatarValorBanco(registro.preco_prazo, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_empreendimento));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/unidade?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empreendimento));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//Reserva

export function initFormReserva(registro = {}) {
	console.log(registro);
    return [
        initialize('unidadeReservaForm', registro)
    ];
}

export function salvarReserva(registro) {
console.log(registro);
    return (dispatch, getState) => {
		axios.post(`${consts.API_URL}/unidadeReserva`, {
			...registro,
			id_usuario_reserva: getState().auth.usuario.id,
			id_usuario_inclusao: getState().auth.usuario.id,
			id_usuario_alteracao: getState().auth.usuario.id
		}).then(resp => {
			if (resp.data.adicionadoFila) {
				setSuccess('Sua reserva foi adicionada na fila');
			} else {
				setSuccess('Reserva Realizada com sucesso');
			}
			dispatch(getLista(registro.id_empreendimento));
			registro.evento();
		}).catch(e => {
			setErroAPI(e);
			dispatch(getLista(registro.id_empreendimento));
		});
    };
}